import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import Blog from '../../../components/blog/layout-three';
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box';
import { truncateString } from '../../../utils/utilFunctions';
import { FeaturePostItem, FeaturePostWrap } from './feature-posts.stc';

const FeaturePosts = ({ blogStyles, widgetStyle }) => {
  const featurePostData = useStaticQuery(graphql`
    query FeaturePostQuery {
      allMarkdownRemark(
        filter: { frontmatter: { is_featured: { eq: true } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            fields {
              slug
              dateSlug
            }
            frontmatter {
              title
              format
              date(formatString: "LL", locale: "pl")
              image {
                childImageSharp {
                  fixed(width: 78, height: 78, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const blogs = featurePostData.allMarkdownRemark.edges.filter(
    (blog, index) => {
      return moment().isAfter(blog.node.fields.dateSlug) && index < 5;
    }
  );

  return (
    <WidgetBox {...widgetStyle} id="feature-post">
      <WidgetTitle>Artykuły</WidgetTitle>
      <FeaturePostWrap>
        {blogs.map(blog => (
          <FeaturePostItem key={blog.node.fields.slug}>
            <Blog
              {...blogStyles}
              content={{
                ...blog.node.fields,
                ...blog.node.frontmatter,
                title: truncateString(blog.node.frontmatter.title, 50)
              }}
            />
          </FeaturePostItem>
        ))}
      </FeaturePostWrap>
    </WidgetBox>
  );
};

FeaturePosts.defaultProps = {
  widgetStyle: {
    bg: 'appricot',
    color: '#fff',
    border: 'none'
  },
  blogStyles: {
    boxCSS: {
      layout: 2
    },
    titleCSS: {
      color: '#fff'
    },
    dateCSS: {
      color: '#fff'
    }
  }
};

export default FeaturePosts;
